import './App.scss';
import React,{useEffect, useState,useRef} from 'react';
import {useMoralis,useWeb3ExecuteFunction,useMoralisFile,useChain} from 'react-moralis';
import {wl_info} from './whitelistInfo.js'
import bg from './images/yf.png'
import { ethers } from 'ethers';
import { BrowserRouter as Router, Routes, Route, Link,useParams } from 'react-router-dom';
import{smartContractAddress,abi} from './contractInfo'
import minus_button from './images/minus_button.png'
import plus_button from './images/plus_button.png'
import { ServerStyleSheet } from 'styled-components';
import { useMediaQuery } from 'react-responsive'


const provider = new ethers.providers.JsonRpcProvider(process.env.REACT_APP_INFURA_KEY_MAINNET);
// const provider = new ethers.providers.Web3Provider(window.ethereum,"any");

// const provider = new ethers.providers.Web3Provider(window.ethereum,"any");
const contract = new ethers.Contract(smartContractAddress,abi,provider);

function Connect(props){
  const { authenticate, logout, isAuthenticated, isAuthenticating, user, chainId, enableWeb3, isWeb3Enabled } = useMoralis();
  const { switchNetwork, chain, account } = useChain();

 

  async function ConnectHandler(){
    await authenticate({signingMessage:"Loomi Heads Authentication",onSuccess:()=>props.setConnected(true)})
    const res = await enableWeb3();
    switchNetwork("0x1");
  }

//   if(!window.ethereum){
//     return(
//       <>
//      {/* <div onClick={()=>{window.open(")}}  style={{borderColor:'red',marginBottom:'30px'}} class="btn-slice" href="#"> */}

//       <div  className="top"><span>Connect Walllet</span></div>
//       <div className="bottom"><span>LOL</span></div>

// </div>
//       </>
//     )
//   }


//  ConnectHandler()
//  if(window.ethereum){
  return(
    <div onClick={()=>ConnectHandler()}  style={{borderColor:'red',marginBottom:'30px',fontFamily:"Mlk",fontSize:'.8rem'}} class="btn-slice" href="#">
          {/* <div onClick={()=>props.connectMe()}  style={{borderColor:'red',marginBottom:'30px'}} class="btn-slice" href="#"> */}


  {(!props.connected) && <div className="top"><span>CONNECT WALLET</span></div>}
  {(!props.connected)&&<div className="bottom"><span>CONNECT WALLET</span></div>}
  {(props.connected) && <div className="top"><span>CONNECTED</span></div>}
  {(props.connected) &&<div className="bottom"><span>CONNECTED</span></div>}
</div>
  )}
// }



function Centered(){
  const[walletAddress,setWalletAddress] = useState(undefined)
  const[connected,setConnected] = useState(false);
  const[signature,setSignature] = useState(undefined);
  const[mintAmount,setMintAmount] = useState(0); //1 is the default value for x
  const {user,isAuthenticated, isWeb3Enabled,authenticate,enableWeb3} = useMoralis();
  const[maxMintAmount,setMaxMintAmount] = useState(0);
  const[totalSupply,setTotalSupply] = useState(0)
  const[whitelistStatus,setWhitelistStatus] = useState('');
  const contractProcessor = useWeb3ExecuteFunction();
  const[mintText,setMintText] = useState("Mint")
  const[signer,setSigner] = useState(null);
  const[accChanged,setAccChanged] = useState(false);
  const[addresses,setAddresses] = useState([])

  async function getTotalSupply(){
    let total_supply = await contract.totalSupply();
   setTotalSupply(total_supply.toString())
  }

  useEffect(()=>{
    getTotalSupply()

  //   const checkConnection = async () => {

     
  //     const addresses = await provider.listAccounts(); 
  //     if (addresses.length){
  //         setWalletAddress(addresses[0])
  //         setMaxMintAmount(wl_info[addresses[0]].max)
  //         setSignature(wl_info[addresses[0]].signature)
  //         setSigner(provider.getSigner());
  // async function getSupply(){
  //   const contract = new ethers.Contract(smartContractAddress,abi,provider);
  //   const totalSupply = await contract.totalSupply();
  //   setTotalSupply(totalSupply.toString())
  // }
  // getSupply()
        
  //         setConnected(true)
          
  //     }
  //     else {
  //         //console.log("User Has Not Signed In")
  //      }
  // };
  // checkConnection();

    
  },[])
  

  
  // window.ethereum.on('accountsChanged', function (accounts) {
  //   const addy = accounts[0]
  //   setWalletAddress(accounts[0])
    
  //   setAddresses(accounts);
  //   // const addresses = provider.listAccounts()
  //   setAccChanged(!accChanged)

  //   setMaxMintAmount(wl_info[ethers.utils.getAddress(addy)].max)
  //   setSignature(wl_info[ethers.utils.getAddress(addy)].signature)
  
  // })

  // useEffect(()=> {
  //     const addresses = provider.listAccounts()
  //     if(addresses.length>0){
  //       setSigner(provider.getSigner())
  //       setConnected(true)
  //     }

  // })

  // useEffect(()=>{

  //   // const addresses = provider.listAccounts()
   
  //     setSignature(wl_info[walletAddress[0]]['max'])
  //     setMaxMintAmount(wl_info[walletAddress[0]]['signature'])

    
  

  // },[walletAddress])


  useEffect( async ()=>{
 if(connected){  let user_address =ethers.utils.getAddress(user.attributes.ethAddress);
  if(!wl_info[user_address]){setWhitelistStatus("Not Approved")}
  else{
    await setMaxMintAmount(wl_info[ethers.utils.getAddress(user.attributes.ethAddress)]['max'])
    setWhitelistStatus("Approved!")
  }}

  },[connected])



  // useEffect( async ()=>{
  //   if(connected){  let user_address =ethers.utils.getAddress(provider.listAccounts()[0]);
  //    if(!wl_info[user_address]){setWhitelistStatus("Not Approved")}
  //    else{
  //      await setMaxMintAmount(wl_info[ethers.utils.getAddress(provider.listAccounts()[0])]['max'])
  //      setWhitelistStatus("Approved!")
  //    }}
   
  //    },[walletAddress])
   

  // async function ethers_mint(){
   
  //   let user_address = ethers.utils.getAddress(user.attributes.ethAddress);
    
  //   const new_contract = new ethers.Contract(smartContractAddress,abi,signer);

  //   const mint = await new_contract.presaleMint(mintAmount,maxMintAmount,signature,{value:ethers.utils.parseUnits(`${.066 * mintAmount}`,'ether')})
    
  // }

  async function connectWallet(){

    
    try{
        const res = await enableWeb3();

        if(res){
            // switchNetwork("0x1")
    
    
        setWalletAddress(user.attributes.ethAddress);
        setSigner(provider.getSigner())
        setConnected(true)

        }
        

    }
    catch(err){}
   

 }

  async function mint(amount) {
    let user_address = ethers.utils.getAddress(user.attributes.ethAddress);
    let options = {
      contractAddress: smartContractAddress,
      functionName: "presaleMint",
      abi: abi,
      params: {
        amount: mintAmount,
        max: maxMintAmount,
        signature: wl_info[user_address].signature
      },
      msgValue: (0.066 * (10 ** 18)) * mintAmount,
    }
    await contractProcessor.fetch({
      params: options,
      onSuccess: () => setMintText("Success!"),
      onError: () => setMintText("User Rejected..")
    })
  }

  function increment(){
    if(mintAmount>= maxMintAmount){
      setMintAmount(maxMintAmount);
      return
    }
    setMintAmount(mintAmount+1);
  }

  function decrement(){   
    if(mintAmount<=1){
      return
    }
    setMintAmount(mintAmount-1);
  }


  return(
    <>
 
 <div className='test'>
 <h1>Whitelist Status: {whitelistStatus}</h1>
    <h1 style={{fontSize:'1.4rem',marginBottom:'2rem'}}>Max Mints: {maxMintAmount}</h1>
   <Connect  setConnected={setConnected} connected={connected} connectMe={connectWallet}/>
     <div  class="btn btn-one">
    <span style={{}}>Loomi Heads Mint</span>
  </div>
   <h1 style={{marginTop:'0px'}}>Total Supply:</h1>
   <h1 style={{fontSize:'6px'}}> {totalSupply}/5555</h1>

   <div className='inc_dec_module'  style={{display:'flex',flexDirection:'row',justifyContent:'space-evenly',width:'60%',fontSize:'1rem'}}>

     <div>
       <img onClick={()=>decrement()} style={{maxWidth:'60px',cursor:'pointer'}} src={minus_button}></img>
     {/* <h1  onClick={()=>decrement()} className='inc_dec'>-</h1> */}

     </div>
    
     <h1 style={{marginTop:'0'}} >{mintAmount}</h1>
     <div>
       <img style={{maxWidth:'60px',cursor:'pointer'}} onClick={()=>increment()} src={plus_button}></img>
     </div>
     {/* <h1 onClick={()=>increment()} className='inc_dec'>+</h1> */}
   </div>


  
  <div class="btn btn-two">
    <span onClick={()=>mint()} className='inc_dec'>{mintText.toUpperCase()}</span>
  </div>

 


  
 </div>
 </>

  )
}


function CenteredPublic(){
  const[connected,setConnected] = useState(false);
  const[mintAmount,setMintAmount] = useState(1); //1 is the default value for x
  const[mintText,setMintText] = useState("Mint");
  const[totalSupply,setTotalSupply] = useState(0)
  const[signer,setSigner] = useState(null);
  const[reset,setReset] = useState(null);
  const {user,isAuthenticated, isWeb3Enabled} = useMoralis();
  const contractProcessor = useWeb3ExecuteFunction();

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })


  const { authenticate, logout, isAuthenticating, chainId, enableWeb3 } = useMoralis();
  const{switchNetwork} = useChain();

  async function ConnectHandler(){
    await authenticate({signingMessage:"Loomi Heads Authentication",onSuccess:()=>setConnected(true)})
    const res = await enableWeb3();
    switchNetwork("0x1");
  }


  async function getTotalSupply(){
    let total_supply = await contract.totalSupply();
   setTotalSupply(total_supply.toString())
  }
  

  useEffect(()=>{
    getTotalSupply()
  },[])
  // function useInterval(callback, delay) {
  //   const savedCallback = useRef();
  
  //   // Remember the latest callback.
  //   useEffect(() => {
  //     savedCallback.current = callback;
  //     async function checkSupply(){
  //       let total_supply = await contract.totalSupply();
  //       setTotalSupply(total_supply.toString())
  //       setTotalSupply(total_supply.toString())

  //     }
  //     checkSupply()
    
  //   }, [callback]);
  
  //   // Set up the interval.
  //   useEffect(() => {
  //     function tick() {
  //       savedCallback.current();
  //     }
  //     if (delay !== null) {
  //       let id = setInterval(tick, delay);
  //       return () => clearInterval(id);
  //     }
  //   }, [delay]);
  // }

  // async function sleep(fn, par) {
  //   return await setTimeout(async function() {
  //     await fn(par)
  //     setReset(!reset);
  //   }, 3000, fn, par);
  // }


  // useEffect(()=>{
  //   getTotalSupply();
  // },[])
  


  async function ethers_mint(){
    
  const new_contract = new ethers.Contract(smartContractAddress,abi,signer);

  const mint = await new_contract.publicMint(mintAmount, {value:ethers.utils.parseUnits(`${.088 * mintAmount}`,'ether')})

    
  }
  useEffect(()=>{
    if(contractProcessor.isFetching){
      setMintText("MINTING...")

      
    }
  },[contractProcessor.isFetching])

  async function mint(amount) {
    if(!connected){
     ConnectHandler();
      return;}
      
      else {
    let user_address = ethers.utils.getAddress(user.attributes.ethAddress);
    let options = {
      contractAddress: smartContractAddress,
      functionName: "publicMint",
      abi: abi,
      params: {
        amount: mintAmount,
      },
      msgValue: (0.088 * (10 ** 18)) * mintAmount,
     
    }
    await contractProcessor.fetch({
      params: options,
      onSuccess: () => setMintText("Success!"),
      onError: () => setMintText("User Rejected...")

    })
  }
  }

  function increment(){
    if(mintAmount>=3){return}
    setMintAmount(mintAmount+1);
  }

  function decrement(){   
    if(mintAmount<=1){
      return
    }
    setMintAmount(mintAmount-1);
  }


  return(
    <>
 
 <div style={{maxWidth:'400px'}} className='test'>
   
{!isTabletOrMobile &&  <div>   
 <h1 style={{textAlign:'center',fontFamily:"Pk",fontWeight:"normal",margin:'0',letterSpacing:'4px'}}>Loomi Heads Sale</h1>
 <h2 style={{textAlign:'center',fontFamily:"Pk",fontWeight:"normal",margin:'0',marginBottom:'20px',letterSpacing:'3px'}}>Max Mints 3</h2>
 </div>
}
{isTabletOrMobile &&  <div>   
 <h1 style={{textAlign:'center',fontFamily:"Pk",fontWeight:"normal",margin:'0',letterSpacing:'4px',fontSize:'1.3rem'}}>Loomi Heads Sale</h1>
 <h2 style={{textAlign:'center',fontFamily:"Pk",fontWeight:"normal",margin:'0',marginBottom:'20px',letterSpacing:'3px',fontSize:'1rem'}}>Max Mints 3</h2>
 </div>
}



   <Connect setConnected={setConnected} connected={connected} setSigner={setSigner} _signer={signer} />
     <div class="btn btn-one">
    <span style={{fontFamily:"Mlk",fontSize:'1.3rem',letterSpacing:'2.5px'}}>Loomi Heads Mint</span>
  </div>
    
   {!isTabletOrMobile && <div>
   <h1 style={{textAlign:'center',fontFamily:"Pk",fontWeight:"normal",letterSpacing:'3.5px',marginBottom:'0',marginTop:'5px'}}  >Total Supply: </h1>
   <h1 style={{textAlign:'center',fontFamily:"Pk",fontWeight:"normal",letterSpacing:'5px',marginTop:'0'}} > {totalSupply}/5555 </h1>
   </div>}

   {isTabletOrMobile && <div>
   <h1 style={{textAlign:'center',fontFamily:"Pk",fontWeight:"normal",letterSpacing:'3.5px',marginBottom:'0',marginTop:'5px',fontSize:'1rem'}}  >Total Supply: </h1>
   <h1 style={{textAlign:'center',fontFamily:"Pk",fontWeight:"normal",letterSpacing:'5px',marginTop:'0',fontSize:'1rem'}} > {totalSupply}/5555 </h1>
   </div>}

   <div className='inc_dec_module'  style={{display:'flex',flexDirection:'row',justifyContent:'space-evenly',width: isTabletOrMobile? '100%' : '60%' ,fontSize:'1rem',alignItems:'center'}}>
   <div>
       <img onClick={()=>decrement()} style={{maxWidth:'60px',cursor:'pointer'}} src={minus_button}></img>
     {/* <h1  onClick={()=>decrement()} className='inc_dec'>-</h1> */}

     </div>

     <div style={{marginTop:'0',fontFamily:"Pk",fontSize:'1.2rem',height:'100%'}}>
     <p style={{marginBottom:'10px'}} >{mintAmount}</p>
     </div>

     <div>
       <img style={{maxWidth:'60px',cursor:'pointer'}} onClick={()=>increment()} src={plus_button}></img>
     </div>
   </div>


  
  <div class="btn btn-two">
    <span style={{fontFamily:"Mlk",fontSize:'1.3rem',letterSpacing:'4px'}} onClick={()=>mint()} className='inc_dec'>{mintText.toUpperCase()}</span>
    
  </div >



  
 </div>
 </>

  )
}


function App() {
  return (
<>

<Router>
  <Routes>
    <Route path='/' element={<CenteredPublic/>}/>
  </Routes>
</Router>

</>
  );
}

export default App;
